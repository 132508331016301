import React, { useState } from "react"

export const closeContext = React.createContext()

const Provider = props => {
  const [isClosed, setClosedState] = useState(true)

  return (
    <closeContext.Provider
      value={{
        isClosed,
        changeState: () => setClosedState(!isClosed),
      }}
    >
      {props.children}
    </closeContext.Provider>
  )
}

export default ({ element }) => <Provider>{element}</Provider>
