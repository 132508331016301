// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

// for react model videos
import 'react-modal-video/scss/modal-video.scss';


// individual component styles sheets to be imported here
import "./src/styles.scss"

import Provider from './src/context/provider';

export const wrapRootElement = Provider;